import constants from '@/utils/constants'

const ROLES = constants.users

export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'return-points/edit/',
    access: Object.values(ROLES)
  },
  { text: 'Сеть', value: 'network', access: Object.values(ROLES) },
  { text: 'Адрес', value: 'address', access: [ROLES.admin, ROLES.manager, ROLES.user] },
  { text: 'Название', value: 'name', access: Object.values(ROLES) },
  { text: 'Юр. лицо', value: 'legal_entity', access: Object.values(ROLES) },
  { text: 'РЦ', value: 'dc', access: [ROLES.admin, ROLES.manager, ROLES.user] },
  { text: 'Время подачи', value: 'delivery', width: '110px', access: Object.values(ROLES) },
  { text: 'Контакты', value: 'contacts', access: [ROLES.admin, ROLES.manager, ROLES.user] },
  {
    text: 'Примечание',
    value: 'note',
    width: '120px',
    colModifiers: [
      { type: 'icon', value: 'mdi-alert-circle',  isRequired: true },
      { type: 'tooltip' },
      { type: 'color', value: 'error' },
      { type: 'align', value: 'center' }
    ],
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  }
]
